import { Controller } from '@hotwired/stimulus';
import type { ModalOptions, ModalInterface } from 'flowbite';
import flowbite from 'flowbite/lib/esm/index.turbo.js';

export default class CommandMenuController extends Controller<HTMLDialogElement> {
  modal?: ModalInterface;

  static targets = ['input', 'turboFrame'];

  declare readonly inputTarget: HTMLInputElement;
  declare readonly turboFrameTarget: HTMLElement;

  connect() {
    this.initializeModel();
  }

  disconnect(): void {
    this.modal?.destroyAndRemoveInstance();
  }

  initializeModel() {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const options: ModalOptions = {
      placement: isTouchDevice ? 'top-center' : 'center',
      backdropClasses: 'bg-gray-900/50 fixed inset-0 z-50',
      closable: true,
      onHide: this.modalClosed.bind(this),
    };

    const instanceOptions = {
      id: this.element.id,
      override: true,
    };

    this.modal = new flowbite.Modal(this.element, options, instanceOptions);
  }

  open() {
    this.modal?.show();
    this.inputTarget.focus();
  }

  close() {
    this.modal?.hide();
  }

  modalClosed() {
    this.inputTarget.value = '';
    this.turboFrameTarget.innerHTML = '';
  }
}
