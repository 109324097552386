import { Controller } from '@hotwired/stimulus';
import { Datepicker } from 'flowbite-datepicker';

export default class MultiDatePickerController extends Controller<HTMLInputElement> {
  datepicker: Datepicker | undefined;

  declare minDateValue: string;
  declare maxDateValue: string;
  declare availableDatesValue: string[];

  static values = {
    minDate: String,
    maxDate: String,
    availableDates: Array,
  };

  connect() {
    this.availableDatesValue = this.availableDatesValue.sort();

    if (this.availableDatesValue.length > 0 && this.minDateValue === '') {
      this.minDateValue = this.availableDatesValue[0];
    }

    if (this.availableDatesValue.length > 0 && this.maxDateValue === '') {
      // eslint-disable-next-line unicorn/prefer-at
      this.maxDateValue = this.availableDatesValue[this.availableDatesValue.length - 1];
    }

    this.datepicker = new Datepicker(this.element, {
      maxNumberOfDates: 1000,
      dateDelimiter: ' ',
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      format: 'yyyy-mm-dd',
      datesDisabled: this.disabledDates,
    });
  }

  get disabledDates() {
    if (this.minDateValue === '' || this.maxDateValue === '' || this.availableDatesValue.length === 0) {
      return [];
    }

    const disabledDates = [];
    let start = new Date(this.minDateValue);
    const end = new Date(this.maxDateValue);

    while (start <= end) {
      const date = start.toISOString().split('T')[0];
      if (!this.availableDatesValue.includes(date)) {
        disabledDates.push(date);
      }
      start = new Date(start.setDate(start.getDate() + 1));
    }

    return disabledDates;
  }
}
