import { Controller } from '@hotwired/stimulus';

export default class AutogrowController extends Controller<HTMLTextAreaElement> {
  static values = {
    manualResize: { type: Boolean, default: true },
    maxHeight: { type: Number, default: null },
  };

  declare manualResizeValue: boolean;
  declare maxHeightValue: number;

  connect() {
    this.resize();

    this.#setManualResize();
  }

  resize() {
    this.element.style.height = 'auto';
    this.element.style.height = `${this.#elementHeight}px`;
  }

  // private

  #setManualResize() {
    if (this.manualResizeValue) {
      return;
    }

    this.element.classList.add('resize-none');
  }

  get #elementHeight() {
    let height = this.element.scrollHeight;

    if (this.maxHeightValue > 0) {
      height = Math.min(height, this.maxHeightValue);
    }

    return height;
  }
}
