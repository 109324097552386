import { FrameElement, StreamActions } from '@hotwired/turbo';
import * as Highcharts from 'highcharts';

import '@hotwired/turbo-rails';
import 'flowbite/lib/esm/index.turbo.js';
import 'chartkick';

window.Highcharts = Highcharts;

import '../controllers';
import '../utilities/confirm-modal';

console.log('CCDC Toolbox Loaded');

StreamActions.turbo_frame_reload = function () {
  for (const element of this.targetElements as FrameElement[]) {
    element.src = element.src || window.location.href;
    void element.reload();
  }
};

StreamActions.turbo_dispatch_event = function () {
  window.dispatchEvent(new CustomEvent(this.target, { detail: { content: this.templateContent.textContent } }));
};

// Close sidebar when navigating to a new page
document.addEventListener('turbo:before-cache', () => {
  const sidebar = document.querySelector('#drawer-navigation');
  const body = document.querySelector('body');
  const drawerBackdrop = document.querySelector('div[drawer-backdrop]');

  if (sidebar) {
    sidebar.classList.remove('transform-none');
    sidebar.classList.add('-translate-x-full');
  }

  if (body) {
    body.classList.remove('overflow-hidden');
  }

  if (drawerBackdrop) {
    drawerBackdrop.remove();
  }
});
