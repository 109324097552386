import { Controller } from '@hotwired/stimulus';

import { leave } from '../utilities/transitions';

export default class RemoveController extends Controller<HTMLElement> {
  remove() {
    void leave(this.element).then(() => {
      this.element.remove();
    });
  }
}
