import { Controller } from '@hotwired/stimulus';
import { FrameElement, TurboSubmitEndEvent } from '@hotwired/turbo';

// Used by modal component
export default class DialogController extends Controller<HTMLElement> {
  static values = { elementId: String };

  declare elementIdValue: string;

  connect() {
    this.element.focus();

    // prevent body scroll
    document.body.classList.add('overflow-hidden');
  }

  hide() {
    this.element.remove();

    // re-apply body scroll
    document.body.classList.remove('overflow-hidden');

    if (this.#dialogTurboFrame) {
      this.#dialogTurboFrame.src = '';
    }
  }

  hideOnSubmit(event: TurboSubmitEndEvent) {
    if (event.detail.success) {
      this.hide();
    }
  }

  disconnect() {
    if (this.#dialogTurboFrame) {
      this.#dialogTurboFrame.src = '';
    }
  }

  // private

  get #dialogTurboFrame() {
    return document.querySelector<FrameElement>(`turbo-frame[id="${this.elementIdValue}"]`);
  }
}
