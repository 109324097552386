import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class AutosubmitController extends Controller<HTMLFormElement> {
  static debounces = ['submit'];

  declare delayValue: number;

  static values = {
    delay: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    useDebounce(this, { wait: this.delayValue });
  }

  submit() {
    this.element.requestSubmit();
  }
}
