import { Context, Controller } from '@hotwired/stimulus';
import type { TurboBeforeFetchResponseEvent, FrameElement } from '@hotwired/turbo';
import { saveAs } from 'file-saver';

import parseContentDisposition from '../utilities/parse-content-disposition';

export default class ReportController extends Controller {
  static targets = ['groupList'];

  declare readonly groupListTarget: FrameElement;

  turboFormSubmitEndHandler: (event: TurboBeforeFetchResponseEvent) => void;

  constructor(context: Context) {
    super(context);
    this.turboFormSubmitEndHandler = this.turboFormSubmitEnd.bind(this);
  }

  connect() {
    this.element.addEventListener('turbo:before-fetch-response', this.turboFormSubmitEndHandler);
  }

  disconnect() {
    this.element.removeEventListener('turbo:before-fetch-response', this.turboFormSubmitEndHandler);
  }

  turboFormSubmitEnd(event: TurboBeforeFetchResponseEvent) {
    const response = event.detail.fetchResponse.response;

    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      const filename = parseContentDisposition(response.headers.get('Content-Disposition'));

      if (contentType === 'application/pdf') {
        event.preventDefault();

        response
          .blob()
          .then((blob) => {
            saveAs(blob, filename ?? 'report.pdf', { autoBom: true });
          })
          .catch((error) => {
            console.error('Failed to save PDF file:', error);
          });
      } else if (contentType === 'application/zip') {
        event.preventDefault();

        response
          .blob()
          .then((blob) => {
            saveAs(blob, filename ?? 'report.zip', { autoBom: true });
          })
          .catch((error) => {
            console.error('Failed to save ZIP file:', error);
          });
      }
    }
  }

  seasonChanged(event: Event) {
    if (!(event.target instanceof HTMLSelectElement)) return;

    const seasonFieldName = event.target.name;
    const seasonId = event.target.value;

    const url = new URL(window.location.href);
    url.searchParams.set(seasonFieldName, seasonId);

    const frame = this.groupListTarget;
    frame.src = url.toString();
  }

  selectAllGroups() {
    const frame = this.groupListTarget;
    for (const checkbox of frame.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')) {
      checkbox.checked = true;
    }
  }

  selectNoGroups() {
    const frame = this.groupListTarget;
    for (const checkbox of frame.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')) {
      checkbox.checked = false;
    }
  }
}
