import { Controller } from '@hotwired/stimulus';
import type { FrameElement } from '@hotwired/turbo';
import { useIntersection } from 'stimulus-use';

export default class TurboFrameRefreshController extends Controller<FrameElement> {
  connect() {
    useIntersection(this);
  }

  appear() {
    void this.element.reload();
  }
}
