import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class ScheduleAssignmentController extends Controller<HTMLElement> {
  static targets = ['form', 'loader', 'fields'];
  static debounces = ['refreshForm'];
  static values = {
    delay: {
      type: Number,
      default: 250,
    },
  };

  declare formTarget: HTMLFormElement;
  declare fieldsTargets: HTMLInputElement[];
  declare loaderTarget: HTMLElement;

  declare delayValue: number;

  connect(): void {
    useDebounce(this, { wait: this.delayValue });
  }

  groupChanged() {
    this.refreshForm();
  }

  dayChanged() {
    this.refreshForm();
  }

  timeChanged() {
    this.refreshForm();
  }

  refreshForm() {
    this.loaderTarget.classList.remove('hidden');

    const refresh = document.createElement('input');
    refresh.type = 'hidden';
    refresh.name = 'refresh';
    refresh.value = 'true';

    this.formTarget.append(refresh);
    this.formTarget.requestSubmit();
    refresh.remove();
  }

  changed(event: Event) {
    const target = event.target as HTMLInputElement;
    const fieldGroup = target.dataset.scheduleAssignmentGroup;

    for (const field of this.fieldsTargets) {
      if (field.dataset.scheduleAssignmentGroup !== fieldGroup) {
        field.value = '';
        field.checked = false;
        field.dispatchEvent(new Event('cleared'));
      }
    }
  }
}
